<template>
<div >
    <h1 class="px-2 mt-4 text-xl font-sans font-semi-bold bg-purple-300 rounded-t-lg">FAQ</h1>
<!-- component -->
  <div class="bg-purple-100">
      <section class="text-gray-700">
        <div class="container px-5 py-24 mx-auto">
          <div class="text-center mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
              Frequently Asked Question
            </h1>
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              The most common questions about how our business works and what
              can do for you.
            </p>
          </div>
          <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
            <div class="w-full lg:w-1/2 px-4 py-2">
              <details class="mb-4">
                <summary class="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  Berapa lama pengerjaan membuat aplikasi atau tampilan website?
                </summary>
                <span class="px-4 py-2">
                  Pengerjaan website tergantung dari tingkat kerumitan tampilan dan
                  tingkat pengguna aplikasi. Pengerjaan untuk website tampilan regular 
                  dibutuhkan waktu 7-14 hari kerja, sedangkan aplikasi berbasis website membutuhkan
                  waktu 14-30 hari kerja. 
                </span>
              </details>
              <details class="mb-4">
                <summary class="font-semibold bg-gray-200 rounded-md py-2 px-4">
                  Dapatkah saya memilih domain website?
                </summary>
                <span class="px-4 py-2">
                  Domain website dapat dipilih sesuai keinginan pelanggan, karena harga produk tertera
                  belum termasuk harga domain dan hosting. Harga tertera adalah harga perkiraan untuk pembuatan
                  website dan aplikasi.
                </span>
              </details>
              <details class="mb-4">
                <summary class="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  Bagaimana Cara Pembayaran yang dapat dilakukan?
                </summary>

                <span class="px-4 py-2">
                  Pembayaran dapat dilakukan dengan cara transfer melalui bank atau aplikasi yang telah kami 
                  sediakan di halaman cara bayar. Pembayaran minimal 50% dari harga yang ditentukan untuk 
                  memulai pembuatan aplikasi.
                </span>
              </details>
              <details class="mb-4">
                <summary class="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  Dapatkah pembayaran dilakukan dengan termin?
                </summary>

                <span class="px-4 py-2">
                  Pembayaran dapat dilakukan dengan sistem termin sebanyak 2x yaitu dengan DP 50% 
                  saat aplikasi akan dibuat dan pelunasan 50% saat aplikasi selesai sesuai kesepakatan
                  kedua belah pihak.
                </span>
              </details>
            </div>
            <div class="w-full lg:w-1/2 px-4 py-2">
              <details class="mb-4">
                <summary class="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  Berapa lama garansi aplikasi diberikan?
                </summary>

                <span class="px-4 py-2">
                  Garansi aplikasi yang kami berikan perbaikan tampilan secara major
                  sebanyak 3x dalam waktu maksimal 1 bulan setelah aplikasi dilakukan serah terima. 
                  Komplain setelah 1 Bulan setelah serah terima hanya dapat dilakukan untuk perbaikan minor. 
                </span>
              </details>
              <details class="mb-4">
                <summary class="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  Bagaimana mendapatkan konsultasi gratis?
                </summary>

                <span class="px-4 py-2">
                  Konsultasi gratis dapat dilakukan dengan menghubungi no whatsapp yang tersedia untuk dijadwalkan
                  meeting online menggunakan aplikasi Zoom Meeting sesuai kesepakatan bersama.
                </span>
              </details>
              <details class="mb-4">
                <summary class="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  Bagaimana saya dapat menghubungi anda?
                </summary>

                <span class="px-4 py-2">
                  Untuk menghubungi bisa melalui whatsapp atau email yang telah kami sediakan. Anda 
                  dapat meghubungi kami dari jam 9.00-18.00 . Setelah lewat jam itu akan dihubungi kembali dihari kerja 
                  berikutnya.
                </span>
              </details>
              <details class="mb-4">
                <summary class="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  Apakah bisa integrasi dengan aplikasi lain?
                </summary>

                <span class="px-4 py-2">
                  Integrasi dengan aplikasi lain dapat dilakukan asal telah tersedia API yang disediakan oleh aplikasi lain 
                  untuk dilakukan integrasi dengan aplikasi yang akan dibuat.
                </span>
              </details>
            </div>
          </div>
        </div>
      </section>
  </div>
</div>
</template>
<script>
  export default {
    el: '#post',
    name : 'FAQ',
    data(){
    return{
                web:'',
    }
  },
    components:{
  },
    mounted() {
  },
    computed: {
  },
    methods:{

    }
}
</script>
